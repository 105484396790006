import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';

import VendorLogos from '../../../assets/image/saasTwo/MultDesk (184 x 63 px).png';

const TrialSection = ({
  sectionWrapper,
  row,
  title,
  description,
  textArea,
  imageArea,
  ImageOne,
  btnStyle,
  outlineBtnStyle,
  buttonWrapper,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...row}>
          <Box {...imageArea}>
            <Image {...ImageOne} src={VendorLogos} alt="VendorLogos" />
          </Box>
          <Box {...textArea}>
            <div>

              <h2><strong>Pol&iacute;tica de privacidade e prote&ccedil;&atilde;o de dados pessoais</strong></h2>

              <p>N&oacute;s, da <strong>MULTDESK</strong>, levamos a privacidade e a prote&ccedil;&atilde;o de dados muito a
                s&eacute;rio dentro da nossa empresa. Estamos comprometidos com a coopera&ccedil;&atilde;o baseada na
                confian&ccedil;a que estabelecemos com voc&ecirc; e nos esfor&ccedil;amos para garantir a sua
                satisfa&ccedil;&atilde;o em todos os aspectos. E isto &eacute; igualmente verdadeiro quando lidamos com os
                seus
                dados pessoais. O objetivo desta pol&iacute;tica de privacidade, e agora em diante denominada apenas
                Pol&iacute;tica, &eacute; inform&aacute;-lo sobre como seus dados pessoais s&atilde;o coletados e
                processados em
                nossas Plataformas.</p>

              <p>A <strong>MULTDESK</strong>&nbsp;&eacute; uma empresa especializada em atendimento online e atendimento
                telef&ocirc;nico, oferecendo ao mercado plataformas de software para que seus clientes implantem &ldquo;o
                moderno conceito de contact center online&rdquo;.</p>

              <p>Esta Pol&iacute;tica n&atilde;o se aplica aos dados que a <strong>MULTDESK</strong> processa em nome de
                nossos
                clientes (Dados do Cliente) em nossa qualidade de operador.&nbsp;&nbsp;Atualizamos periodicamente esta
                Pol&iacute;tica. Publicaremos eventuais altera&ccedil;&otilde;es &agrave; Pol&iacute;tica de
                Privacidade&nbsp;em
                nosso site.&nbsp;Tamb&eacute;m manteremos vers&otilde;es anteriores desta Pol&iacute;tica de Privacidade em
                arquivo para fins de consulta.&nbsp;</p>

              <h2><strong>Quais s&atilde;o os direitos dos titulares de dados?</strong></h2>

              <p>Voc&ecirc; sempre poder&aacute; optar em n&atilde;o divulgar seus dados para n&oacute;s, mas tenha em mente
                que
                alguns desses dados podem ser necess&aacute;rios para se registrar em nossas plataformas ou usar alguns de
                nossos recursos e produtos que oferecemos. Independente disso, voc&ecirc; sempre possuir&aacute; direitos
                relativos a privacidade e a prote&ccedil;&atilde;o dos seus dados pessoais. Al&eacute;m de nos preocuparmos
                com
                a seguran&ccedil;a desses dados, tamb&eacute;m nos preocupamos que voc&ecirc; tenha acesso e conhecimento de
                todos os seus direitos relativos a dados pessoais.&nbsp;</p>

              <p>Dessa forma, abaixo resumimos todos os direitos que voc&ecirc; tem sob as leis setoriais brasileiras
                relativas&nbsp;a&nbsp;prote&ccedil;&atilde;o de dados, a Lei Geral de Prote&ccedil;&atilde;o de Dados (LGPD)
                e
                a General Data&nbsp;Protection&nbsp;Regulation (GDPR), seus direitos em rela&ccedil;&atilde;o a
                <strong>MULTDESK</strong>&nbsp;incluem o seguinte:&nbsp;
              </p>

              <ul>
                <li><strong>Direito de&nbsp;</strong><strong>confirma&ccedil;&atilde;o do tratamento;</strong>&nbsp;</li>
                <li><strong>Direito de acesso</strong><strong>;</strong>&nbsp;</li>
                <li><strong>Direito de retifica&ccedil;&atilde;o;</strong>&nbsp;</li>
                <li><strong>Direito de&nbsp;</strong><strong>solicitar&nbsp;</strong><strong>anonimiza&ccedil;&atilde;o,
                  bloqueio ou elimina&ccedil;&atilde;o</strong><strong>;</strong><strong>&nbsp;</strong>&nbsp;</li>
                <li><strong>Direito &agrave; portabilidade de dados;</strong>&nbsp;</li>
                <li><strong>Direito a informa&ccedil;&atilde;o sobre compartilhamento;</strong>&nbsp;</li>
                <li><strong>Direito &agrave; informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o fornecer
                  consentimento
                  e sobre as consequ&ecirc;ncias da negativa;</strong>&nbsp;</li>
                <li><strong>Direito de retirar o seu consentimento;</strong></li>
                <li><strong>Direito de Peti&ccedil;&atilde;o;</strong>&nbsp;</li>
                <li><strong>Direito de oposi&ccedil;&atilde;o ao tratamento.</strong>&nbsp;</li>
              </ul>

              <p>Nesse sentido, segue abaixo uma breve explica&ccedil;&atilde;o e exemplos sobre alguns desses direitos:&nbsp;
              </p>

              <ul>
                <li>Requisi&ccedil;&atilde;o de&nbsp;confirma&ccedil;&atilde;o e&nbsp;acesso aos seus dados pessoais. Este
                  direito permite que voc&ecirc; possa&nbsp;saber se os seus dados est&atilde;o sendo tratados pela
                  MULTDESK,&nbsp;requisitar e receber uma c&oacute;pia dos seus dados pessoais possu&iacute;mos&nbsp;em
                  nossa
                  base de dados;&nbsp;</li>
                <li>Requisi&ccedil;&atilde;o de retifica&ccedil;&atilde;o dos seus dados pessoais na&nbsp;Multedesk. Este
                  direito permite que voc&ecirc;, a qualquer momento, possa solicitar a corre&ccedil;&atilde;o e/ou
                  retifica&ccedil;&atilde;o dos seus dados pessoais, caso voc&ecirc; identifique que alguns deles
                  est&atilde;o
                  incorretos. Contudo, para ser efetivada essa corre&ccedil;&atilde;o, teremos que checar a validade dos
                  dados
                  que voc&ecirc; nos&nbsp;fornecer;&nbsp;</li>
                <li>Requisi&ccedil;&atilde;o de exclus&atilde;o dos seus dados pessoais. Este direito permite que voc&ecirc;
                  possa solicitar a exclus&atilde;o dos seus dados pessoais das nossas plataformas. Todos os dados
                  coletados
                  ser&atilde;o exclu&iacute;dos de nossos servidores quando voc&ecirc; assim requisitar, ou quando estes
                  n&atilde;o forem mais necess&aacute;rios, ou relevantes para lhe oferecermos os nossos servi&ccedil;os,
                  salvo se houver qualquer outra raz&atilde;o para a sua manuten&ccedil;&atilde;o, como eventual
                  obriga&ccedil;&atilde;o legal de reten&ccedil;&atilde;o de dados ou necessidade de
                  preserva&ccedil;&atilde;o
                  destes para resguardo de direitos da&nbsp;MULTDESK. Para alterar suas informa&ccedil;&otilde;es pessoais
                  ou
                  exclu&iacute;-las do nosso banco de dados, basta enviar um e-mail para <a
                    href="mailto:support@multdesk.com.br">support@multdesk.com.br</a>;</li>
                <li>Direito de&nbsp;opor-se&nbsp;a um processamento de dados. Quando estivermos confiando em um
                  leg&iacute;timo
                  interesse e houver algo sobre sua situa&ccedil;&atilde;o espec&iacute;fica que fa&ccedil;a com que
                  voc&ecirc; deseje se opor ao processamento, pois tal causa impacto aos seus direitos e liberdades
                  fundamentais. Voc&ecirc;, tamb&eacute;m, tem o direito de contestar o nosso processamento. Em alguns
                  casos,
                  podemos demonstrar que temos motivos leg&iacute;timos para processar seus dados, que acabam se
                  sobrepondo
                  aos seus direitos, como em situa&ccedil;&otilde;es que a coleta &eacute; essencial para fornecer os
                  nossos
                  servi&ccedil;os a voc&ecirc;;&nbsp;</li>
                <li>Restringir o processamento dos seus dados pessoais. Este direito permite que voc&ecirc; nos pe&ccedil;a
                  para
                  suspender o processamento de seus dados pessoais nos seguintes cen&aacute;rios: (a) se voc&ecirc; quiser
                  que
                  n&oacute;s estabele&ccedil;amos a precis&atilde;o dos dados; (b) quando voc&ecirc; precisar que mantenha
                  os
                  dados, para estabelecer, exercer ou defender reivindica&ccedil;&otilde;es legais, mesmo se n&atilde;o
                  precisarmos mais deles; ou (c) voc&ecirc; se op&ocirc;s ao uso de seus dados, mas precisamos verificar
                  se
                  temos motivos leg&iacute;timos para us&aacute;-los;&nbsp;</li>
                <li>Solicitar a transfer&ecirc;ncia de seus dados pessoais para voc&ecirc; ou para terceiros. Forneceremos a
                  voc&ecirc;, ou a terceiros que voc&ecirc; escolheu, seus dados pessoais em formato estruturado e
                  interoper&aacute;vel. Observe que esse direito se aplica somente as informa&ccedil;&otilde;es
                  automatizadas
                  que voc&ecirc; forneceu inicialmente para nosso uso ou onde usamos as informa&ccedil;&otilde;es para
                  celebrar um contrato com voc&ecirc;;&nbsp;</li>
                <li>Direito de retirar o consentimento a qualquer momento. Voc&ecirc; tem o direito de retirar o seu
                  consentimento. No entanto, isso n&atilde;o afetar&aacute; a legalidade de qualquer processamento
                  realizado
                  antes de voc&ecirc; retirar o seu consentimento. Se voc&ecirc; retirar o seu consentimento, talvez
                  n&atilde;o possamos fornecer determinados produtos. E se for este o caso, iremos avis&aacute;-lo no
                  momento
                  em que isso ocorrer.&nbsp;</li>
              </ul>

              <p>Talvez seja necess&aacute;rio solicitar informa&ccedil;&otilde;es espec&iacute;ficas suas para nos ajudar a
                confirmar sua identidade e garantir seu direito de acessar seus dados pessoais (ou de exercer seus outros
                direitos). Esta &eacute; uma medida de seguran&ccedil;a para garantir que os dados pessoais n&atilde;o sejam
                divulgados a qualquer pessoa que n&atilde;o tenha direito de receb&ecirc;-los. Podemos tamb&eacute;m
                contat&aacute;-lo para obter mais informa&ccedil;&otilde;es em rela&ccedil;&atilde;o &agrave; sua
                solicita&ccedil;&atilde;o, a fim de acelerar nossa resposta.&nbsp;Tentamos&nbsp;responder a todas as
                solicita&ccedil;&otilde;es leg&iacute;timas dentro de 48 horas. Ocasionalmente, esse prazo pode&nbsp;superar
                15
                dias,&nbsp;caso sua solicita&ccedil;&atilde;o seja particularmente complexa ou se voc&ecirc; tiver feito
                v&aacute;rias solicita&ccedil;&otilde;es. Neste caso, iremos notific&aacute;-lo e mant&ecirc;-lo atualizado
                sobre o andamento da sua solicita&ccedil;&atilde;o.&nbsp;</p>

              <p>Caso voc&ecirc; tenha alguma d&uacute;vida sobre essas quest&otilde;es e sobre como voc&ecirc; pode exercer
                esses
                direitos, fique &agrave; vontade para entrar em contato conosco <a
                  href="mailto:support@multdesk.com.br">support@multdesk.com.br</a>.&nbsp;</p>

              <p>&nbsp;</p>

              <h2><strong>Tipo de dados coletados e finalidade do uso</strong></h2>

              <p>&nbsp;</p>

              <p>O dom&iacute;nio (<a href="https://multdesk.com.br">www.multdesk.com.br</a>) &eacute; de propriedade
                da&nbsp;MD&amp;L INTELIGENCIA DE NEGOCIOS LTDA, inscrita no CNPJ&nbsp;41.496.228/0001-00&nbsp;que o
                administra
                tanto em seu pr&oacute;prio nome&nbsp;e&nbsp;interesse.&nbsp;Atrav&eacute;s deste site e seus
                subdom&iacute;nios, coletamos dados pessoais em diferentes se&ccedil;&otilde;es e para diferentes fins
                (cookies,
                entrar em contato conosco, obter informa&ccedil;&otilde;es, enviar curr&iacute;culos, registrar-se no
                site&nbsp;etc.&nbsp;Algumas dessas se&ccedil;&otilde;es possuem pol&iacute;ticas de privacidade
                espec&iacute;ficas, fornecidas, de acordo com a legisla&ccedil;&atilde;o em vigor.&nbsp;</p>

              <p>Solicita-se aos usu&aacute;rios e visitantes do site, que leiam cuidadosamente essas
                informa&ccedil;&otilde;es
                gerais e as espec&iacute;ficas.&nbsp;&nbsp;</p>

              <p>Podemos coletar os dados de visitantes no nosso site por cookies. Estes s&atilde;o pequenos arquivos de texto
                armazenados localmente no seu sistema.&nbsp;</p>

              <p>Novamente, esta Pol&iacute;tica n&atilde;o cobre o uso de cookies por terceiros e n&atilde;o somos
                respons&aacute;veis por suas pol&iacute;ticas e pr&aacute;ticas de privacidade. Tenha em
                aten&ccedil;&atilde;o
                que os cookies colocados por terceiros podem eventualmente continuar a monitorizar as suas
                atividades online,
                mesmo depois de ter sa&iacute;do dos nossos servi&ccedil;os.&nbsp;</p>

              <p>N&oacute;s postamos em nossos Sites depoimentos e coment&aacute;rios de clientes, os quais podem conter Dados
                Pessoais. N&oacute;s obtemos o consentimento de cada cliente via e-mail antes de postar seu nome e
                depoimento.&nbsp;</p>

              <p>Coletamos dados pessoais em nosso site&nbsp;de acordo com o princ&iacute;pio necessidade ou
                minimiza&ccedil;&atilde;o.&nbsp;</p>

              <ul>
                <li>Melhorar sua experi&ecirc;ncia de navega&ccedil;&atilde;o com a personaliza&ccedil;&atilde;o dos Sites;
                </li>
                <li>Enviar a voc&ecirc; informa&ccedil;&otilde;es ou conte&uacute;dos que achamos ser do seu interesse por
                  meio
                  de publica&ccedil;&otilde;es, e-mails ou outros meios, bem como enviar comunica&ccedil;&otilde;es de
                  marketing relativas &agrave; nossa empresa;</li>
                <li>Promover o uso dos nossos servi&ccedil;os e compartilhar com voc&ecirc; conte&uacute;dos promocionais e
                  informativos obedecendo &agrave;s suas prefer&ecirc;ncias de comunica&ccedil;&atilde;o;</li>
                <li>Fornecer a outras empresas estat&iacute;sticas sobre os nossos usu&aacute;rios, mas esses dados
                  n&atilde;o
                  ser&atilde;o usados para identificar nenhum usu&aacute;rio espec&iacute;fico e ser&atilde;o
                  compartilhados
                  apenas de forma agregada e anonimizada;</li>
                <li>Entrar em contato para abordagens relacionadas a produtos e ofertas espec&iacute;ficas, mediante
                  consentimento do titular do dado.</li>
                <li>Enviar a voc&ecirc; informa&ccedil;&otilde;es sobre mudan&ccedil;as nos nossos Termos de Servi&ccedil;o
                  ao
                  Cliente, Pol&iacute;tica de Privacidade (inclusive a Pol&iacute;tica de Cookies) ou outros documentos
                  jur&iacute;dicos;</li>
                <li>Investigar e auxiliar na preven&ccedil;&atilde;o de problemas de seguran&ccedil;a e abuso;</li>
                <li>Atender a requisitos legais.</li>
              </ul>

              <p>&nbsp;</p>

              <h2><strong>Direitos dos titulares de dados</strong></h2>

              <p>Voc&ecirc; sempre poder&aacute; optar em n&atilde;o divulgar seus dados para n&oacute;s, mas tenha em mente
                que
                alguns desses dados podem ser necess&aacute;rios para se registrar em nossas plataformas ou usar alguns de
                nossos recursos e produtos que oferecemos. Independente disso, voc&ecirc; sempre possuir&aacute; direitos
                relativos a privacidade e a prote&ccedil;&atilde;o dos seus dados pessoais. Al&eacute;m de nos preocuparmos
                com
                a seguran&ccedil;a desses dados, tamb&eacute;m nos preocupamos que voc&ecirc; tenha acesso e conhecimento de
                todos os seus direitos relativos a dados pessoais.&nbsp;</p>

              <p>Dessa forma, abaixo resumimos todos os direitos que voc&ecirc; tem sob as leis setoriais brasileiras
                relativas&nbsp;a&nbsp;prote&ccedil;&atilde;o de dados, a Lei Geral de Prote&ccedil;&atilde;o de Dados (LGPD)
                e
                a General Data&nbsp;Protection&nbsp;Regulation (GDPR), seus direitos em rela&ccedil;&atilde;o
                a&nbsp;MULTDESK
                incluem o seguinte:&nbsp;</p>

              <ol>
                <li><strong>Direito de&nbsp;</strong><strong>confirma&ccedil;&atilde;o do tratamento;</strong>&nbsp;</li>
                <li><strong>Direito de acesso</strong><strong>;</strong>&nbsp;</li>
                <li><strong>Direito de retifica&ccedil;&atilde;o;</strong>&nbsp;</li>
                <li><strong>Direito de&nbsp;</strong><strong>solicitar&nbsp;</strong><strong>anonimiza&ccedil;&atilde;o,
                  bloqueio ou elimina&ccedil;&atilde;o</strong><strong>;</strong><strong>&nbsp;</strong>&nbsp;</li>
                <li><strong>Direito &agrave; portabilidade de dados;</strong>&nbsp;</li>
                <li><strong>Direito a informa&ccedil;&atilde;o sobre compartilhamento;</strong>&nbsp;</li>
                <li><strong>Direito &agrave; informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o fornecer
                  consentimento
                  e sobre as consequ&ecirc;ncias da negativa;</strong>&nbsp;</li>
                <li><strong>Direito de retirar o seu consentimento;</strong></li>
                <li><strong>Direito de Peti&ccedil;&atilde;o;</strong>&nbsp;</li>
                <li><strong>Direito de oposi&ccedil;&atilde;o ao tratamento.</strong>&nbsp;</li>
              </ol>

              <p>Nesse sentido, segue abaixo uma breve explica&ccedil;&atilde;o e exemplos sobre alguns desses direitos:&nbsp;
              </p>

              <ul>
                <li>Requisi&ccedil;&atilde;o de&nbsp;confirma&ccedil;&atilde;o e&nbsp;acesso aos seus dados pessoais. Este
                  direito permite que voc&ecirc; possa&nbsp;saber se os seus dados est&atilde;o sendo tratados pela
                  MULTDESK,&nbsp;requisitar e receber uma c&oacute;pia dos seus dados pessoais possu&iacute;mos&nbsp;em
                  nossa
                  base de dados;&nbsp;</li>
                <li>Requisi&ccedil;&atilde;o de retifica&ccedil;&atilde;o dos seus dados pessoais na&nbsp;MULTDESK. Este
                  direito
                  permite que voc&ecirc;, a qualquer momento, possa solicitar a corre&ccedil;&atilde;o e/ou
                  retifica&ccedil;&atilde;o dos seus dados pessoais, caso voc&ecirc; identifique que alguns deles
                  est&atilde;o
                  incorretos. Contudo, para ser efetivada essa corre&ccedil;&atilde;o, teremos que checar a validade dos
                  dados
                  que voc&ecirc; nos&nbsp;fornecer;&nbsp;</li>
                <li>Requisi&ccedil;&atilde;o de exclus&atilde;o dos seus dados pessoais. Este direito permite que voc&ecirc;
                  possa solicitar a exclus&atilde;o dos seus dados pessoais das nossas plataformas. Todos os dados
                  coletados
                  ser&atilde;o exclu&iacute;dos de nossos servidores quando voc&ecirc; assim requisitar, ou quando estes
                  n&atilde;o forem mais necess&aacute;rios, ou relevantes para lhe oferecermos os nossos servi&ccedil;os,
                  salvo se houver qualquer outra raz&atilde;o para a sua manuten&ccedil;&atilde;o, como eventual
                  obriga&ccedil;&atilde;o legal de reten&ccedil;&atilde;o de dados ou necessidade de
                  preserva&ccedil;&atilde;o
                  destes para resguardo de direitos da&nbsp;MULTDESK. Para alterar suas informa&ccedil;&otilde;es pessoais
                  ou
                  exclu&iacute;-las do nosso banco de dados, basta enviar um e-mail para&nbsp;<a
                    href="mailto:support@multdesk.com.br"
                    title="support@multdesk.com.br">support@multdesk.com.br;</a>&nbsp;
                </li>
                <li>Direito de&nbsp;opor-se&nbsp;a um processamento de dados. Quando estivermos confiando em um
                  leg&iacute;timo
                  interesse e houver algo sobre sua situa&ccedil;&atilde;o espec&iacute;fica que fa&ccedil;a com que
                  voc&ecirc; deseje se opor ao processamento, pois tal causa impacto aos seus direitos e liberdades
                  fundamentais. Voc&ecirc;, tamb&eacute;m, tem o direito de contestar o nosso processamento. Em alguns
                  casos,
                  podemos demonstrar que temos motivos leg&iacute;timos para processar seus dados, que acabam se
                  sobrepondo
                  aos seus direitos, como em situa&ccedil;&otilde;es que a coleta &eacute; essencial para fornecer os
                  nossos
                  servi&ccedil;os a voc&ecirc;;&nbsp;</li>
                <li>Restringir o processamento dos seus dados pessoais. Este direito permite que voc&ecirc; nos pe&ccedil;a
                  para
                  suspender o processamento de seus dados pessoais nos seguintes cen&aacute;rios: (a) se voc&ecirc; quiser
                  que
                  n&oacute;s estabele&ccedil;amos a precis&atilde;o dos dados; (b) quando voc&ecirc; precisar que mantenha
                  os
                  dados, para estabelecer, exercer ou defender reivindica&ccedil;&otilde;es legais, mesmo se n&atilde;o
                  precisarmos mais deles; ou (c) voc&ecirc; se op&ocirc;s ao uso de seus dados, mas precisamos verificar
                  se
                  temos motivos leg&iacute;timos para us&aacute;-los;&nbsp;</li>
                <li>Solicitar a transfer&ecirc;ncia de seus dados pessoais para voc&ecirc; ou para terceiros. Forneceremos a
                  voc&ecirc;, ou a terceiros que voc&ecirc; escolheu, seus dados pessoais em formato estruturado e
                  interoper&aacute;vel. Observe que esse direito se aplica somente as informa&ccedil;&otilde;es
                  automatizadas
                  que voc&ecirc; forneceu inicialmente para nosso uso ou onde usamos as informa&ccedil;&otilde;es para
                  celebrar um contrato com voc&ecirc;;&nbsp;</li>
                <li>Direito de retirar o consentimento a qualquer momento. Voc&ecirc; tem o direito de retirar o seu
                  consentimento. No entanto, isso n&atilde;o afetar&aacute; a legalidade de qualquer processamento
                  realizado
                  antes de voc&ecirc; retirar o seu consentimento. Se voc&ecirc; retirar o seu consentimento, talvez
                  n&atilde;o possamos fornecer determinados produtos. E se for este o caso, iremos avis&aacute;-lo no
                  momento
                  em que isso ocorrer.&nbsp;</li>
              </ul>

              <p>Talvez seja necess&aacute;rio solicitar informa&ccedil;&otilde;es espec&iacute;ficas suas para nos ajudar a
                confirmar sua identidade e garantir seu direito de acessar seus dados pessoais (ou de exercer seus outros
                direitos). Esta &eacute; uma medida de seguran&ccedil;a para garantir que os dados pessoais n&atilde;o sejam
                divulgados a qualquer pessoa que n&atilde;o tenha direito de receb&ecirc;-los. Podemos tamb&eacute;m
                contat&aacute;-lo para obter mais informa&ccedil;&otilde;es em rela&ccedil;&atilde;o &agrave; sua
                solicita&ccedil;&atilde;o, a fim de acelerar nossa resposta.&nbsp;Tentamos&nbsp;responder a todas as
                solicita&ccedil;&otilde;es leg&iacute;timas dentro de 48 horas. Ocasionalmente, esse prazo pode&nbsp;superar
                15
                dias,&nbsp;caso sua solicita&ccedil;&atilde;o seja particularmente complexa ou se voc&ecirc; tiver feito
                v&aacute;rias solicita&ccedil;&otilde;es. Neste caso, iremos notific&aacute;-lo e mant&ecirc;-lo atualizado
                sobre o andamento da sua solicita&ccedil;&atilde;o.&nbsp;</p>

              <p>Caso voc&ecirc; tenha alguma d&uacute;vida sobre essas quest&otilde;es e sobre como voc&ecirc; pode exercer
                esses
                direitos, fique &agrave; vontade para entrar em contato conosco <a
                  href="mailto:support@multdesk.com.br">support@multdesk.com.br</a>;</p>

              <p>&nbsp;</p>

              <h2>Compartilhamento dos dados coletados</h2>

              <p>Por oferecer o acesso &agrave;s plataformas, a MULTDESK&nbsp;poder&aacute; operar em conjunto com outras
                empresas
                numa ampla gama de atividades,&nbsp;incluindo para fornecer as funcionalidades de localiza&ccedil;&atilde;o,
                anunciantes e parceiros comerciais, online e offline, al&eacute;m de ferramentas de
                divulga&ccedil;&atilde;o.&nbsp;Desta forma, nos reservamos no direito de compartilhar suas
                informa&ccedil;&otilde;es, incluindo dados de localiza&ccedil;&atilde;o e cadastro, com as empresas abaixo
                indicadas, sempre que for poss&iacute;vel, de forma an&ocirc;nima, visando preservar ao m&aacute;ximo a sua
                privacidade.&nbsp;</p>

              <p>Sob nenhuma circunst&acirc;ncia, transmitimos&nbsp;seus dados pessoais a terceiros&nbsp;para fins de
                publicidade
                ou marketing sem o seu consentimento.&nbsp;</p>

              <p><strong>NOSSOS PARCEIROS:</strong> Empregamos outras empresas para realizar trabalhos em nosso nome e
                precisamos
                compartilhar seus dados pessoais com eles para fornecer produtos e servi&ccedil;os para voc&ecirc;. Por
                exemplo,
                usamos servi&ccedil;os de hospedagem de dados para armazenar a nossa base de dados, usamos tamb&eacute;m
                servi&ccedil;os de meios de pagamento para poder processar os dados de faturamento dos nossos produtos
                adquiridos por voc&ecirc;. Nossos parceiros somente s&atilde;o autorizados a utilizar os dados pessoais para
                os
                fins espec&iacute;ficos que eles foram contratados, portanto, eles n&atilde;o ir&atilde;o utilizar os seus
                dados
                pessoais para outras finalidades, al&eacute;m as da presta&ccedil;&atilde;o de servi&ccedil;os previstas
                contratualmente.&nbsp;</p>

              <p><strong>ANALYTICS:</strong> Os dados armazenados pela&nbsp;MULTDESK podem vir a ser utilizados para fins de
                estat&iacute;sticas (analytics), com a finalidade daMULTDESK compreender quem s&atilde;o as pessoas que
                visitam
                seus sites e que s&atilde;o consumidores dos seus produtos e servi&ccedil;os.&nbsp;Estes dados
                s&atilde;o&nbsp;pseudonimiz&aacute;veis&nbsp;e n&atilde;o buscam identificar ou tornar identific&aacute;vel
                os
                titulares dos dados pessoais, mas t&atilde;o somente compreender melhor como &eacute; o acesso deles nas
                plataformas da&nbsp;MULTDESK, a fim de melhorar a presta&ccedil;&atilde;o de servi&ccedil;os e customizar
                produtos mais direcionados aos interesses dos usu&aacute;rios.&nbsp;</p>

              <p><strong>PARA RESGUARDAR E PROTEGER DIREITOS DA&nbsp;FORTICS
                TECNOLOGIA</strong><strong>:</strong> Reservamo-nos o
                direito de acessar, ler, preservar e divulgar quaisquer dados que acreditamos serem necess&aacute;rios para
                cumprir uma obriga&ccedil;&atilde;o legal ou uma ordem judicial; fazer cumprir ou aplicar nossos termos de
                uso e
                outros acordos; ou proteger os direitos, propriedade ou seguran&ccedil;a da&nbsp;MULTDESK, nossos
                funcion&aacute;rios, nossos usu&aacute;rios ou outros.&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Transfer&ecirc;ncia internacional de dados</h2>

              <p>&nbsp;</p>

              <p>A&nbsp;FORTICS TECNOLOGIA&nbsp;poder&aacute; armazenar e tratar os dados coletados em servidores de empresas
                localizados em outros pa&iacute;ses, incluindo pa&iacute;ses membros dos Estados Unidos da Am&eacute;rica.
                Na
                contrata&ccedil;&atilde;o desses servi&ccedil;os, a&nbsp;<strong>MULTDESK</strong> sempre buscar&aacute;
                empresas com alto n&iacute;vel de seguran&ccedil;a da informa&ccedil;&atilde;o, estabelecendo contratos que
                n&atilde;o violem as defini&ccedil;&otilde;es de privacidade previstas nesta Pol&iacute;tica. Como uma
                camada
                maior de seguran&ccedil;a nestes relacionamentos com parceiros, todos os dados trafegados entre as partes
                s&oacute; ocorrer&atilde;o sob n&iacute;veis de criptografias j&aacute; estabelecidas no mercado, que
                s&atilde;o
                revisadas e testadas periodicamente.&nbsp;</p>

              <p>A&nbsp;FORTICS TECNOLOGIA&nbsp;tamb&eacute;m poder&aacute; compartilhar os dados coletados com parceiros
                localizados em pa&iacute;ses diferentes do seu pa&iacute;s de origem. Ressaltamos que nos casos de
                transfer&ecirc;ncia internacional de dados, a&nbsp;<strong>MULTDESK</strong>&nbsp;estabelece
                obriga&ccedil;&otilde;es nos contratos de transfer&ecirc;ncia com os parceiros, de modo a manter os mesmos
                padr&otilde;es de seguran&ccedil;a e privacidade de dados presentes nesta pol&iacute;tica de privacidade.
              </p>

              <p>&nbsp;</p>

              <h2>Por quanto tempo os dados ser&atilde;o armazenados?</h2>

              <p>&nbsp;</p>

              <p>N&oacute;s manteremos seus dados pessoais somente pelo tempo que for necess&aacute;rio para cumprir com as
                finalidades para as quais os coletamos, inclusive para fins de cumprimento de quaisquer
                obriga&ccedil;&otilde;es
                legais, contratuais, de presta&ccedil;&atilde;o de contas ou requisi&ccedil;&atilde;o de autoridades
                competentes.&nbsp;</p>

              <p>Para determinar o per&iacute;odo de reten&ccedil;&atilde;o adequado para os dados pessoais, consideramos a
                quantidade, a natureza e a sensibilidade dos dados pessoais, o risco potencial de danos decorrentes do uso
                n&atilde;o autorizado ou da divulga&ccedil;&atilde;o de seus dados pessoais, a finalidade de processamento
                dos
                seus dados pessoais e se podemos alcan&ccedil;ar tais prop&oacute;sitos atrav&eacute;s de outros meios, e os
                requisitos legais aplic&aacute;veis.&nbsp;</p>

              <p>Caso optemos por&nbsp;anonimizar, os dados ser&atilde;o utilizados somente internamente, para fins de
                an&aacute;lise de performance, hist&oacute;rica e t&eacute;cnicas de &ldquo;analytics&rdquo;, sem
                compartilhamento dos dados com terceiros.&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Padr&otilde;es de&nbsp;Seguran&ccedil;a?</h2>

              <p>&nbsp;</p>

              <p>A&nbsp;MULTDESK trabalha com prestadores de servi&ccedil;o de Data Center com
                certifica&ccedil;&otilde;es.&nbsp;Toda a aplica&ccedil;&atilde;o &eacute; periodicamente testada pelo time
                de
                seguran&ccedil;a da informa&ccedil;&atilde;o da companhia, que realiza testes de vulnerabilidades, tanto
                tecnol&oacute;gicas, como de engenharia social com seus colaboradores, visando identificar, corrigir e/ou
                educar.&nbsp;</p>

              <p>Toda a rede de servidores &eacute; segregada virtualmente, permitindo que apenas pessoas previamente
                autorizadas,
                atrav&eacute;s de solicita&ccedil;&atilde;o via sistema interno tenham acesso &agrave;s redes de servidores
                e
                sistemas atrav&eacute;s de conex&atilde;o pela VPN. Seguimos os padr&otilde;es e as pol&iacute;ticas de
                gest&atilde;o de credenciais para controlar e auditar os acessos de todos os colaboradores.</p>
            </div>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

TrialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object,
};

TrialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    className: 'trial-section',
    pt: ['20px', '40px', '60px', '80px'],
    pb: ['0px', '0px', '0px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textArea: {
    width: ['100%', '100%', '80%', '80%'],
  },
  imageArea: {
    width: ['100%', '100%', '43%'],
    mb: ['35px', '35px', '40px', '40px'],
  },
  title: {
    fontSize: ['28px', '32px', '36px', '42px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '28px',
    textAlign: 'center',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['15px', '15px', '16px', '16px', '16px'],
    color: '#5c636c',
    lineHeight: '2.1',
    textAlign: 'center',
    mb: ['35px', '35px', '40px', '60px'],
  },
  ImageOne: {
    ml: 'auto',
    mr: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'secondaryWithBg',
    pl: ['15px', '30px'],
    pr: ['15px', '30px'],
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#0f2137',
    colors: 'secondary',
  },
};

export default TrialSection;
