import React, { Fragment } from 'react';
import SEO from '../components/seo';
import { ThemeProvider } from 'styled-components';
import { saasTwoTheme } from 'common/src/theme/saasTwo';
import { ResetCSS } from 'common/src/assets/css/style';
import {
    GlobalStyle,
    ContentWrapper,
} from 'common/src/containers/SaasTwo/sassTwo.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from 'common/src/containers/SaasTwo/Navbar';
import Footer from 'common/src/containers/SaasTwo/Footer';
import PoliticaPrivacidadeSection from 'common/src/containers/SaasTwo/PoliticaPrivacidade';

const PoliticaPrivacidade = () => (
    <ThemeProvider theme={saasTwoTheme}>
        <Fragment>
            <SEO title="MultDesk | Política de Privacidade" />
            <ResetCSS />
            <GlobalStyle />
            <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                    <DrawerProvider>
                        <Navbar />
                    </DrawerProvider>
                </Sticky>
            </ContentWrapper>
            <PoliticaPrivacidadeSection />
            <Footer />
        </Fragment>
    </ThemeProvider>
);

export default PoliticaPrivacidade;
